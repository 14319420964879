:root {
  --purple-lighter: #e6ccff;
  --purple-light: #b58dd9;
  --purple: #502379;
  --purple-dark: #19022d;
  --purple-darker: #0d0019;

  --green-lighter: #d1ffe9;
  --green-light: #94fcc9;
  --green: #1bbe6f;
  --green-dark: #005029;
  --green-darker: #00190d;

  --red-lighter: #ffd6cc;
  --red-light: #ffad93;
  --red: #e63e12;
  --red-dark: #641400;
  --red-darker: #190500;

  --yellow-lighter: #fff1cc;
  --yellow-light: #ffe787;
  --yellow: #fdc220;
  --yellow-dark: #926f0d;
  --yellow-darker: #191300;

  --blue-lighter: #dff5ff;
  --blue-light: #c3f1ff;
  --blue: #82d0f4;
  --blue-dark: #30708e;
  --blue-darker: #031219;

  --gray-lighter: #ffffff;
  --gray-light: #edecee;
  --gray: #c2bfc4;
  --gray-dark: #726f76;
  --gray-darker: #181719;

  --on-purple-lighter: var(--purple-darker);
  --on-purple-light: var(--purple-darker);
  --on-purple: var(--gray-lighter);
  --on-purple-dark: var(--gray-lighter);
  --on-purple-darker: var(--gray-lighter);

  --on-green-lighter: var(--green-darker);
  --on-green-light: var(--green-darker);
  --on-green: var(--gray-lighter);
  --on-green-dark: var(--gray-lighter);
  --on-green-darker: var(--gray-lighter);

  --on-red-lighter: var(--red-darker);
  --on-red-light: var(--red-darker);
  --on-red: var(--gray-lighter);
  --on-red-dark: var(--gray-lighter);
  --on-red-darker: var(--gray-lighter);

  --on-yellow-lighter: var(--yellow-darker);
  --on-yellow-light: var(--yellow-darker);
  --on-yellow: var(--yellow-darker);
  --on-yellow-dark: var(--gray-lighter);
  --on-yellow-darker: var(--gray-lighter);

  --on-blue-lighter: var(--blue-darker);
  --on-blue-light: var(--blue-darker);
  --on-blue: var(--blue-darker);
  --on-blue-dark: var(--gray-lighter);
  --on-blue-darker: var(--gray-lighter);

  --on-gray-lighter: var(--gray-darker);
  --on-gray-light: var(--gray-darker);
  --on-gray: var(--gray-darker);
  --on-gray-dark: var(--gray-lighter);
  --on-gray-darker: var(--gray-lighter);

  --alpha-more: 0.8;
  --alpha: 0.6;
  --alpha-less: 0.2;

  --blur: 20px;

  --timing: 0.2s ease-in-out;

  --font-family: "Ubuntu", "Noto Kufi Arabic", "Geeza Pro", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  --basis: 1rem;
  --basis_x0_5: calc(var(--basis) * 0.5);
  --basis_x2: calc(var(--basis) * 2);
  --basis_x4: calc(var(--basis) * 4);
  --basis_x8: calc(var(--basis) * 8);
}

* {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  /*
    Ubuntu = Volt (It includes latin and arabic characters.)

    Noto Kufi Arabic = A Noto font from Google (maybe it's installed)
    Geeza Pro = macOS Arabic Default

    -apple-system, BlinkMacSystemFont = macOS Default
    Helvetica Neue = Old macOS Default
    Segoe UI = Windows Default
    Roboto = Android Default
    Fira Sans = Firefox OS Default
    Oxygen, Cantarell, Droid Sans = Linux

    sans-serif = Fallback
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

/*
:root {
  --text-color: #502379;
  --background-color: #fff;
  --primary-color: #502379;

  --content-box-width: 620px;
}
@media (prefers-color-scheme: dark) {
  :root {
    --text-color: #fff;
    --background-color: #502379;
    --primary-color: #fff;
  }
}
*/
:root {
  --text-color: #fff;
  --background-color: #502379;
  --primary-color: #fff;
}

:root {
  --font-family-website-title: var(--font-family);
  --font-family-headline: var(--font-family);
  --font-family-text: var(--font-family);
  --font-family-button: var(--font-family);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth !important;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-family-text);
  transition: color 0.2s ease, background-color 0.2s ease;
  padding-block-end: 25vh;
  overflow-x: hidden;
}

h1 {
  font-family: var(--font-family-website-title);
  font-weight: bold;
  font-size: 80px;
  letter-spacing: -1.5px;
  line-height: 1.1;
}
@media (max-width: 700px) {
  h1 {
    font-size: 50px;
  }
}

h2 {
  font-family: var(--font-family-headline);
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 40px;
}

h3 {
  font-family: var(--font-family-headline);
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0px;
}

body,
p {
  font-family: var(--font-family-text);
  font-weight: normal;
  font-size: 22px;
  letter-spacing: 0.5px;
  line-height: 1.2;
}
@media (max-width: 700px) {
  body,
  p {
    font-size: 20px;
  }
}

.body2 {
  font-family: var(--font-family-text);
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.25px;
}

button {
  font-family: var(--font-family-button);
  text-decoration: none;

  font-weight: inherit;
  font-size: inherit;
  letter-spacing: inherit;

  /* text-transform: uppercase; */
  border: 0;
  font-weight: bold;
  cursor: pointer;

  padding: 10px 20px;
  border-radius: 0;
  color: var(--background-color);
  fill: var(--background-color);
  background: var(--text-color);
  box-shadow: 10px 10px 0 0 var(--purple-dark);
  font-weight: bold;
  transition: box-shadow 0.2s ease;
}
button.text_red {
  color: var(--red);
  fill: var(--red);
  background: var(--on-red);
}
button.text_blue {
  color: var(--blue-dark);
  fill: var(--blue-dark);
  background: var(--on-blue-dark);
}
button.text_green {
  color: var(--green);
  fill: var(--green);
  background: var(--on-green);
}
button.text_yellow {
  color: var(--yellow-dark);
  fill: var(--yellow-dark);
  background: var(--on-yellow-dark);
}
button.small {
  padding: 5px 10px;
  font-size: 14px;
}
button[data-selected="true"] {
  box-shadow: 5px 5px 0 0 var(--purple-dark);
}
button:not(:disabled):hover {
  box-shadow: 5px 5px 0 0 var(--purple-dark);
  text-decoration: none;
}
button.small:not(:disabled):hover {
  box-shadow: 5px 5px 0 0 var(--purple-dark);
}
button:not(:disabled):active,
button:not(:disabled):focus,
button:not(:disabled).active,
a.active button:not(:disabled) {
  box-shadow: 0 0 0 0 var(--purple-dark);
  text-decoration: none;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

a,
.link {
  color: var(--primary-color);
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
}

a:hover,
.link:hover {
  color: var(--primary-color);
  text-decoration: underline;
  /* text-decoration-style: double; */
  text-decoration-color: currentColor;
  opacity: var(--alpha);
}

hr {
  border: 0;
  height: 1px;
  background-color: var(--text-color);
  margin: 0;
}

p {
  margin-block-end: 20px;
}
